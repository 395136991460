import * as Yup from "yup";

export const phoneSchema = Yup.string()
  .test(
    'is-phone-provided',
    'E-mail lub numer telefonu jest wymagany.',
    (value, context) => {
      if(value && value.trim().length > 0) {
        return true;
      }
      return context.parent.email && context.parent.email.trim().length > 0;
    }
  );

export const emailSchema = Yup.string()
  .test(
    'is-email-provided',
    'E-mail lub numer telefonu jest wymagany.',
    (value, context) => {
      if(value && value.trim().length > 0) {
        return true;
      }
      return context.parent.phone && context.parent.phone.trim().length > 0;
    }
  )