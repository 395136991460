import * as React from "react"
import {Router} from "@reach/router";
import {CartProvider} from "../../components/cart-context";
import OrderPage from "./order-page";
import CartPage from "./cart-page";
import RailguardConfigurator from "./railguard-configurator";
import ConfiguratorLayout from "./layout";
import ThankYouPage from "./thank-you-page";
import OrderPlacementFailed from "./order-placement-failed";
import SEO from "../../components/seo";

const IndexPage = () => {
  return (
    <>
      <SEO title="Konfigurator" />
      <Router basepath={"/configure"}>
        <ConfiguratorLayout path={"/"}>
          <CartProvider path="/">
            <OrderPage path="/order"/>
            <CartPage path="/cart"/>
            <RailguardConfigurator path="/"/>
          </CartProvider>
          <ThankYouPage path={"/thankyou"}/>
          <OrderPlacementFailed path={"/order-placement-failed"}/>
        </ConfiguratorLayout>
      </Router>
    </>
  )
}

export default IndexPage
