import {callApi} from "./api";

const headers = {
  'Content-Type': 'application/json'
}

const OrderClient = () => {
  const order = async (purchaserType, formData) => {
    const response = await callApi(`/api/public/${purchaserType}`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: headers
    });

    if (response.status === 200) {
      return;
    } else if (response.status === 404) {
      throw new CartNotFound();
    } else {
      throw new Error("Could not place an order");
    }
  }

  return ({
    orderAsNaturalPerson: async (formData) => {
      return await order("naturalPersonOrder", formData);
    },
    orderAsCompany: async (formData) => {
      return await order("companyOrder", formData);
    }
  });
}

export class CartNotFound extends Error {

}

export default OrderClient;