import {Form, FormikProvider, useFormik} from "formik";
import React, {useState} from "react";
import * as Yup from 'yup';
import {useCartState} from "../../cart-context";
import TextInput from "../../forms/text-input";
import Grid from "@material-ui/core/Grid";
import BottomNav from "../bottom-nav";
import useTheme from "@material-ui/core/styles/useTheme";
import Paper from "@material-ui/core/Paper";
import LoadingOverlay from "../../overlay";
import {emailSchema, phoneSchema} from "../yup-validation";
import Checkbox from "../../forms/checkbox";
import {Link} from "gatsby";

const NaturalPersonForm = ({orderClient, onOrderSent, onOrderSendingFailed}) => {
  const {cart} = useCartState();
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      name: '',
      streetAddress: '',
      zipCode: '',
      city: '',
      email: '',
      phone: '',
      termsAndConditionsAccepted: false
    },
    onSubmit: async (values) => {
      await orderClient.orderAsNaturalPerson({
        cartId: cart.id,
        ...values
      })
    },
    validationSchema: Yup.object({
      name: Yup.string().required('To pole jest wymagane'),
      streetAddress: Yup.string().required('To pole jest wymagane'),
      zipCode: Yup.string().required('To pole jest wymagane'),
      city: Yup.string().required('To pole jest wymagane'),
      email: emailSchema,
      phone: phoneSchema,
      termsAndConditionsAccepted: Yup.boolean().oneOf([true], "Regulamin musi zostać zaakceptowany.")
    }),
    validateOnMount: true
  });

  return (
    <FormikProvider value={formik}>
      <LoadingOverlay open={formik.isSubmitting}/>
      <Form>
        <Paper
          elevation={1}
          style={{
            paddingBottom: theme.spacing(6),
            paddingTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(1)
          }}>
          <Grid container spacing={1} justify={"center"}>
            <Grid container spacing={3} xs={8} item>
              <Grid item xs={12} md={12}>
                <TextInput
                  label="Imię i nazwisko"
                  id="name"
                  name="name"
                  type="text"
                  inputProps={{
                    maxLength: 200
                  }}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  label="Adres"
                  placeholder="Np. Główna 12A/7"
                  id="streetAddress"
                  name="streetAddress"
                  type="text"
                  inputProps={{
                    maxLength: 200
                  }}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextInput
                  label="Miasto"
                  id="city"
                  name="city"
                  type="text"
                  inputProps={{
                    maxLength: 50
                  }}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextInput
                  label="Kod pocztowy"
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  inputProps={{
                    maxLength: 10
                  }}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="E-mail"
                  id="email"
                  name="email"
                  type="text"
                  inputProps={{
                    maxLength: 100
                  }}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Numer telefonu"
                  id="phone"
                  name="phone"
                  type="text"
                  inputProps={{
                    maxLength: 30
                  }}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  onChange={formik.handleChange}
                  id="termsAndConditionsAccepted"
                  name="termsAndConditionsAccepted"
                  label={(
                    <p>Zapoznałem się z <Link style={{ textDecoration: "underline" }} to={"/regulamin"}>regulaminem sklepu internetowego</Link> i akceptuję jego treść.</p>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Form>
      <BottomNav onSend={() => {
        formik
          .submitForm()
          .then(() => {
            if (formik.isValid) {
              onOrderSent();
            }
          })
          .catch(onOrderSendingFailed);
      }} canSend={formik.isValid}/>
    </FormikProvider>
  )
}

export default NaturalPersonForm;