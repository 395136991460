import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import React from "react";
import {useField, useFormikContext} from "formik";
import MaterialCheckbox from "@material-ui/core/Checkbox";

const Checkbox = ({label, ...rest}) => {
  const [field, meta] = useField(rest);

  const formikContext = useFormikContext();

  const isTouched = meta.touched || formikContext.submitCount > 0;

  return (
    <FormControl error={isTouched && Boolean(meta.error)}>
      <FormControlLabel
        control={<MaterialCheckbox {...rest} />}
        label={label}
      />
      {
        isTouched && meta.error ?
          <FormHelperText>{meta.error}</FormHelperText> :
          null
      }
    </FormControl>
  );
}

export default Checkbox;