import Container from "@material-ui/core/Container";
import React, {useEffect, useState} from "react";
import {graphql, Link as GatsbyLink, useStaticQuery} from "gatsby";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {createTheme, ThemeProvider, useTheme} from "@material-ui/core/styles";
import GlobalStyle from "../components/global-style";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import Helmet from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";

const Footer = styled.div`
  border-top: 2px black solid;
  background-color: #565656;
  color: white;
  padding: 0 ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(3)}px ${props => props.theme.spacing(3)}px;
`;

const CompanyLink = styled.a`
  color: ${props => props.theme.palette.secondary.light};
  font-weight: bold;
  text-decoration: none;
`;

const FooterLink = styled.a`
  color: white;
  ${props => props.bold && `font-weight: bold;`}
  text-decoration: none;
`;

const FooterGatsbyLink = styled(GatsbyLink)`
  color: white;
  ${props => props.bold && `font-weight: bold;`}
  text-decoration: none;
`;

const MenuBar = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? `column` : `row`};
  align-items: ${props => props.isMobile ? `stretch` : `center`};
  padding-top: 12px;
  padding-bottom: 6px;
  border-bottom: 2px black solid;
`;

const Menu = styled.div`
  display: flex;  
  flex-direction: row;
  justify-content: ${props => props.isMobile ? `space-between` : undefined};
`;

const Content = styled.div`
  padding: ${props => props.theme.spacing(3)}px;
  background-color: #f5f5f5;
  overflow: hidden;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

const MenuLinksContainer = styled.div`
  display: ${props => (
  props.isMobile ? (props.isOpen ? `flex` : `none`) : `flex`
)};
  flex-direction: ${props => props.isMobile ? `column` : `row`}; 
  align-items: stretch;
`;

const StyledGatsbyLink = styled(GatsbyLink)`
  padding: 12px;
  color: black;
  text-decoration: none;
  display: flex;
  align-self: stretch;
  
  &:hover {
    background-color: black;
    color: white;
  }
`;

const StyledContainer = styled(Container)`
  && {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

const BurgerMenuButton = styled.button`
  background-color: #DADADA;
  border: none;
  display: ${props => props.isMobile ? `block` : `none`};
  
  &:hover {
    background-color: #DFDFDF;
  }
`;

const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 0px 4px 0px;
  align-items: center;
  
  svg {
    margin-right: 12px;
  }  
`;

const PRIVACY_POLICY_ACCEPTED_KEY = "privacyPolicyAccepted";

const theme = createTheme({
  palette: {
    primary: {
      main: '#343a40',
    },
    secondary: {
      main: '#ffc107',
    }
  },
});

const MenuLink = (props) => (
  <StyledGatsbyLink
    {...props}
    getProps={({isCurrent}) => {
      return {
        style: {
          ...(isCurrent && {backgroundColor: "#ebb513"})
        }
      };
    }}
  />
);

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

const MartechLogoContainer = styled.div`
  width: 150px;
  margin-right: 12px;
`;

const Layout = ({children}) => {
  return (
    <>
      <GlobalStyle/>
      <ThemeProvider theme={theme}>
        <LayoutInner>
          {children}
        </LayoutInner>
      </ThemeProvider>
    </>
  );
};

const LayoutInner = ({children}) => {
  const theme = useTheme();

  const data = useStaticQuery(graphql`
    query NonPageQuery {
      logoImage: file(relativeDirectory: {eq: "website"}, name: {eq: "logo"}) {
        base
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 150)
        }
      }
    }
  `);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);

  const isItMobileWidth = () => {
    const windowWidth = getWidth();

    return windowWidth < 750;
  }

  const updateMobileSettings = () => {
    if (isItMobileWidth()) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setIsOpen(false);
    }
  }

  const acceptPrivacyPolicy = () => {
    localStorage.setItem(PRIVACY_POLICY_ACCEPTED_KEY, "true");
    setPrivacyPolicyAccepted(true);
  };

  useEffect(() => {
    updateMobileSettings();
  }, []);

  useEffect(() => {
    const wasPrivacyPolicyAccepted = localStorage.getItem(PRIVACY_POLICY_ACCEPTED_KEY);
    if (wasPrivacyPolicyAccepted === "true") {
      setPrivacyPolicyAccepted(true);
    }
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      updateMobileSettings()
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <StyledContainer>
        <MenuBar isMobile={isMobile}>
          <Menu isMobile={isMobile}>
            <MartechLogoContainer>
              <GatsbyLink to={"/"}>
                <GatsbyImage image={data.logoImage.childImageSharp.gatsbyImageData} />
              </GatsbyLink>
            </MartechLogoContainer>
            <BurgerMenuButton isMobile={isMobile} onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <CloseIcon/> : <MenuIcon/>}
            </BurgerMenuButton>
          </Menu>
          <MenuLinksContainer isMobile={isMobile} isOpen={isOpen}>
            <MenuLink to={"/"}>O nas</MenuLink>
            <MenuLink to={"/configure"}>Konfigurator</MenuLink>
            <MenuLink to={"/katalog"}>Katalog</MenuLink>
            <MenuLink to={"/jak-zamawiac"}>Jak zamawiać?</MenuLink>
            <MenuLink to={"/wysylka"}>Wysyłka</MenuLink>
          </MenuLinksContainer>
        </MenuBar>
        <Content theme={theme}>
          {children}
        </Content>
        <Footer theme={theme}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h3>
                Kontakt
              </h3>
              <FooterLinkContainer>
                <LanguageIcon/>
                <span>
              Odwiedź <FooterLink bold href={"https://martech-sg.pl"}>naszą stronę firmową</FooterLink>
            </span>
              </FooterLinkContainer>
              <FooterLinkContainer>
                <EmailIcon/>

                <span>
              Napisz na <FooterLink bold
                                    href={"mailto:kontakt@odbojnice.sklep.pl"}>kontakt@odbojnice.sklep.pl</FooterLink>
              </span>
              </FooterLinkContainer>
              <FooterLinkContainer>
                <PhoneIcon/>

                <span>
              Zadzwoń na <FooterLink bold href={"tel:+48 600 819 114"}>+48 600 819 114</FooterLink>
            </span>
              </FooterLinkContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <h3>Pomoc</h3>
              <FooterLinkContainer>
                <FooterGatsbyLink to={"/regulamin"}>Regulamin</FooterGatsbyLink>
              </FooterLinkContainer>
              <FooterLinkContainer>
                <FooterGatsbyLink to={"/polityka-prywatnosci"}>Polityka prywatności</FooterGatsbyLink>
              </FooterLinkContainer>
            </Grid>
            <Grid item xs={12} style={{textAlign: "center", paddingTop: isMobile ? "24px" : "0"}}>
              <span>Realizacja: <CompanyLink theme={theme}
                                             href={"https://toporowicz.it"}>toporowicz.it</CompanyLink></span>
            </Grid>
          </Grid>
        </Footer>
      </StyledContainer>
    </>
  )
}

export default Layout;