import { useState, useEffect } from 'react';

const initialDimensionsToWorkaroundNoWindowObjectDuringGatsbyBuild = {
  width: 1200,
  height: 800
}

export default function useWindowDimensions() {
  const isBrowser = typeof window !== 'undefined';
  const [windowDimensions, setWindowDimensions] = useState(initialDimensionsToWorkaroundNoWindowObjectDuringGatsbyBuild);

  useEffect(() => {
    if(!isBrowser) {
      return;
    }

    function getWindowDimensions() {
      const width = Math.min(window.innerWidth, window.screen.width || Number.MAX_SAFE_INTEGER);
      const height = window.innerHeight;

      return {
        width,
        height
      };
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    setWindowDimensions(getWindowDimensions());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [isBrowser]);

  return windowDimensions;
}
