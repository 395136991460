import React from "react";
import styled from "styled-components";
import {parameterTypes} from "../parameters";
import {railguardTypes} from "../railguardTypes";
import PriceCell from "./price-cell";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import useTheme from "@material-ui/core/styles/useTheme";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const ItemDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeleteButton = styled(Button)`
  && {
    color: #f44336;
  }
`;

const StyledQuantityBtn = styled(IconButton)`
  & {
  ${props => props.ml && `margin-left: ${props.ml}px;`}
  ${props => props.mr && `margin-right: ${props.mr}px;`}
  }
`

const QuantityBtn = (props) => {
  return (
    <StyledQuantityBtn
      variant={"contained"}
      color={"primary"}
      size={"small"}
      {...props} />
  );
}

const CartItem = ({item, onIncreaseQuantity, onDecreaseQuantity, onRemove, readonly}) => {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell>
        {railguardTypes[item.type].name}
      </TableCell>
      <TableCell>
        <ItemDescriptionContainer>
          {
            Object.keys(item.params).map(param => (
              <span key={param.name}><strong>{ parameterTypes[param].name }</strong>: { parameterTypes[param].formatForDisplay(item.params[param]) }</span>
            ))
          }
        </ItemDescriptionContainer>
      </TableCell>
      <PriceCell>{item.unitPrice} zł</PriceCell>
      <TableCell>
        {!readonly && <QuantityBtn
          mr={theme.spacing(3)}
          disabled={item.quantity === 1}
          onClick={onDecreaseQuantity}
          aria-label={"decrease quantity"}
        >
          <RemoveIcon />
        </QuantityBtn>
        }
        {item.quantity}
        {!readonly && <QuantityBtn
          ml={theme.spacing(3)}
          onClick={onIncreaseQuantity}
          aria-label={"increase quantity"}
        >
          <AddIcon />
        </QuantityBtn>
        }
      </TableCell>
      <PriceCell>{item.totalPrice} zł</PriceCell>
      <TableCell align={"right"}>
        <DeleteButton onClick={onRemove} startIcon={<DeleteIcon/>}>Usuń</DeleteButton>
      </TableCell>
    </TableRow>
  )
}

export default CartItem;