import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import useTheme from "@material-ui/core/styles/useTheme";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import {useNavigate} from "@reach/router";

const ThankYouPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box style={{padding: theme.spacing(3), backgroundColor: "#f5f5f5", textAlign: "center"}}>
        <Paper elevation={1} style={{ padding: theme.spacing(3)}}>
          <h1>Dziękujemy</h1>
          <p>Dziękujemy za złożenie zamówienia. Skontaktujemy się z Państwem tak szybko jak to możliwe.</p>
          <Button onClick={() => navigate("/configure")}>Powrót do sklepu</Button>
        </Paper>
      </Box>
    </Container>
  );
}

export default ThankYouPage;