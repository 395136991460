import {callApi} from "./api";

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

const CartClient = () => ({
  createNewCart: async () => {
    const response = await callApi(`/api/public/cart`, {
      method: "POST",
      headers: headers
    });

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error("Could not create new cart");
    }
  },
  getCart: async (cartId) => {
    const response = await callApi(`/api/public/cart/${cartId}`, {
      method: "GET",
      headers: headers
    });

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404) {
      throw new CartNotFound();
    } else {
      throw new Error("Could not create new cart");
    }
  },
  addItem: async (cartId, type, quantity, params) => {
    const response = await callApi(`/api/public/cart/${cartId}/items`, {
      method: "POST",
      body: JSON.stringify({type: type, quantity: quantity, params: params}),
      headers: headers
    });

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404) {
      throw new CartNotFound();
    } else {
      throw new Error("Could not update quantity");
    }
  },
  updateQuantity: async (cartId, itemId, quantity) => {
    const response = await callApi(`/api/public/cart/${cartId}/items/${itemId}`, {
      method: "PUT",
      body: JSON.stringify({quantity: quantity}),
      headers: headers
    });

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404) {
      throw new CartNotFound();
    } else {
      throw new Error("Could not update quantity");
    }
  },
  removeItem: async (cartId, itemId) => {
    const response = await callApi(`/api/public/cart/${cartId}/items/${itemId}`, {
      method: "DELETE",
      headers: headers
    });

    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404) {
      throw new CartNotFound();
    } else {
      throw new Error("Could not create new cart");
    }
  }
});

export class CartNotFound extends Error {

}

export default CartClient;