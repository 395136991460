import OrderForm from "../../components/order/form";
import React, {useEffect} from "react";
import {useNavigate} from "@reach/router";
import {useCartActions, useCartState} from "../../components/cart-context";

const OrderPage = () => {
  const navigate = useNavigate();
  const { cart } = useCartState();
  const { reset } = useCartActions();

  useEffect(() => {
    if(cart.items.length === 0) {
      navigate("/configure");
    }
  }, []);

  return (
    <>
      <OrderForm
        onOrderSent={() => {
          reset(() => navigate("/configure/thankyou"));
        }}
        onOrderSendingFailed={() => navigate("/configure/order-placement-failed")}
      />
    </>
  )
}

export default OrderPage;