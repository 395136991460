import React, {useState} from "react";
import NaturalPersonForm from "./natural-person/natural-person";
import OrderClient from "../order-client";
import CompanyForm from "./company/company";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const typeCompany = "company";
const typeNaturalPerson = "naturalPerson";

const orderClient = OrderClient();

const OrderForm = ({onOrderSent, onOrderSendingFailed}) => {
  const [formType, setFormType] = useState(typeCompany);

  return (
    <>
      <h4>Dane do zamówienia</h4>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="firma czy osoba fizyczna"
          name="formType"
          value={formType}
          onChange={(e) => setFormType(e.target.value)}
        >
          <FormControlLabel value={typeCompany} control={<Radio />} label="Firma" />
          <FormControlLabel value={typeNaturalPerson} control={<Radio />} label="Osoba fizyczna" />
        </RadioGroup>
      </FormControl>

      {
        formType === typeCompany ?
          <CompanyForm orderClient={orderClient} onOrderSent={onOrderSent} onOrderSendingFailed={onOrderSendingFailed}/> :
          <NaturalPersonForm orderClient={orderClient} onOrderSent={onOrderSent} onOrderSendingFailed={onOrderSendingFailed}/>
      }
    </>
  );
}

export default OrderForm;