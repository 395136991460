import RailguardGallery from "../../components/picker/railguard-gallery";
import ParamsPicker from "../../components/picker/params-picker";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "@reach/router";
import LoadingOverlay from "../../components/overlay";
import { useCartActions, useCartState } from "../../components/cart-context";
import { BottomNavContainer } from "../../components/general/bottom-nav-container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useGuardrailTypes } from "../../hooks/useGuardrailTypes";
import FinishConfig from "../../components/picker/finish-config";
import { usePrice } from "../../hooks/usePrice";
import PriceDisplay from "../../components/picker/price-display";
import { useGuardrailPickingParams } from "../../hooks/useGuardrailPickingParams";

const RailguardConfigurator = (props) => {
  const { selectType, setSelectedValues, state: { selectedType, selectedValues } } = useGuardrailPickingParams();

  const { price, isLoading: isPriceLoading } = usePrice({ railguardType: selectedType, selectedValues });
  const navigate = useNavigate();
  const { updating, cart } = useCartState();
  const { addItem } = useCartActions();
  const { railguardTypes, guardrailImageByType } = useGuardrailTypes();

  const shouldShowLoadingOverlay = updating || isPriceLoading;

  const uriToCart = `/configure/cart`;

  const selectedValuesAsObject = () => {
    let selectedValuesObj = {};
    selectedValues.forEach((value, key) => {
      Object.assign(selectedValuesObj, { [key]: value });
    });
    return selectedValuesObj;
  }

  const onItemAddedToCart = () => {
    selectType(undefined);
    navigate(uriToCart);
  };

  const onAddToCart = (quantity, color) => addItem(
    selectedType,
    {
      ...selectedValuesAsObject(),
      ...((color && { "c": color }) || {})
    },
    quantity
  ).then(() => onItemAddedToCart());

  return (
    <>
      <LoadingOverlay open={shouldShowLoadingOverlay} />
      <Box pb={3} pt={3}>
        <h4>Wybierz typ odbojnicy</h4>
        <RailguardGallery
          guardrailImageByType={guardrailImageByType}
          railguardTypes={Object.keys(railguardTypes)}
          selectedType={selectedType}
          onSelect={(type) => selectType(type)}
        />
      </Box>
      {
        selectedType &&
        <>
          <ParamsPicker
            railguardType={selectedType}
            selectedValues={selectedValues}
            setSelectedValues={(params) => setSelectedValues(params)}
          />
          <PriceDisplay price={price} />
          {price ?
            <Box p={3} spacing={3}>
              <h4>Dokończ konfigurację</h4>
              <FinishConfig
                shouldAskForColor={selectedValues.get("p") === "true"}
                onAddToCart={onAddToCart}
              />
            </Box> :
            null
          }
        </>
      }
      <BottomNavContainer>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button variant={"outlined"} onClick={() => navigate(-1)} fullWidth>Wstecz</Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={"outlined"}
              onClick={() => navigate(uriToCart)}
              disabled={cart.items.length === 0}
              fullWidth
            >Dalej</Button>
          </Grid>
        </Grid>
      </BottomNavContainer>
    </>
  )
}

export default RailguardConfigurator;