import {useLocation} from "@reach/router";
import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Layout from "../../layout/layout";
import styled from "styled-components"

const steps = [
  {label: "Konfiguracja produktów", path: "/configure"},
  {label: "Podgląd zamówienia", path: "/configure/cart"},
  {label: "Złożenie zamówienia", path: "/configure/order"}
];

const ConfiguratorBox = styled.div`
  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
  }
  
  text-align: center;
`;

const ConfiguratorLayout = (props) => {
  const {pathname} = useLocation();
  const {width} = useWindowDimensions();

  const currentStepIndex = steps
    .map(step => step.path)
    .findIndex(path => path === pathname || path + "/" === pathname);

  const isVertical = width < 400;

  return (
    <Layout>
      <ConfiguratorBox>
        <Stepper activeStep={currentStepIndex} alternativeLabel={!isVertical}
                 orientation={isVertical ? "vertical" : "horizontal"}>
          {steps.map(({label}) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {props.children}
      </ConfiguratorBox>
    </Layout>
  );
}

export default ConfiguratorLayout;