import { useEffect, useState } from "react";
import { callApi } from "../components/api";

const validator = {
    "d": (value) => value !== undefined,
    "l": (value) => value !== undefined,
    "h": (value) => value !== undefined,
    "depth": (value) => value !== undefined,
    "g": (value) => value !== undefined,
    "p": (value) => value !== undefined && (value === "false" || value !== ""),
    "mm": (value) => value !== undefined,
  }

export const usePrice = ({ railguardType, selectedValues }) => {
    const [price, setPrice] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    let areValuesForAllParamsProvided = true;
    selectedValues.forEach((value, key) => {
        if (!validator[key](value)) {
            areValuesForAllParamsProvided = false;
        }
    });

    useEffect(() => {
        if (selectedValues.size === 0 || !areValuesForAllParamsProvided) {
            return;
        }

        setIsLoading(true);

        let urlParamPairs = [];
        selectedValues.forEach((value, key) => {
            urlParamPairs.push([key, value]);
        });
        const urlParams = urlParamPairs
            .map(pair => `${pair[0]}=${pair[1]}`)
            .join("&");

        const abortApiCall = new AbortController();

        callApi(`/api/public/pricing/${railguardType}?${urlParams}`, { signal: abortApiCall.signal })
            .then(response => response.json())
            .then(data => {
                const price = data["price"];
                setPrice(price);
            })
            .finally(() => setIsLoading(false));

        return () => {
            abortApiCall.abort();
        }
    }, [railguardType, selectedValues]);

    useEffect(() => {
        setPrice(undefined);
    }, [railguardType]);

    return { 
        price, 
        isLoading 
    }
}