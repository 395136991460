import React from "react";
import {useCartActions, useCartState} from "../cart-context";
import CartItem from "./cart-item";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import PriceCell from "./price-cell";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/Button";

const Cart = ({readonly = false, onAddAnother}) => {
  let {cart} = useCartState();
  let {updateQuantity, removeItem} = useCartActions();

  if (!cart || cart.items.length === 0) {
    return (
      <>
        <p>Twoje zamówienie jest puste.</p>
        <Button
          variant={"contained"}
          color="secondary"
          startIcon={<AddIcon />}
          onClick={onAddAnother}
        >
          Dodaj produkt do zamówienia
        </Button>
      </>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="cart" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Typ</TableCell>
              <TableCell>Parametry</TableCell>
              <PriceCell>Cena jednostkowa <br/>(zawiera VAT 23%)</PriceCell>
              <TableCell>Ilość</TableCell>
              <PriceCell>Łączna cena <br/>(zawiera VAT 23%)</PriceCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              cart.items.map(item => (
                <CartItem
                  readonly={readonly}
                  key={item.id}
                  item={item}
                  onDecreaseQuantity={() => updateQuantity(item.id, item.quantity - 1)}
                  onIncreaseQuantity={() => updateQuantity(item.id, item.quantity + 1)}
                  onRemove={() => removeItem(item.id)}
                />
              ))
            }
            <TableRow>
              <TableCell colSpan={1}/>
              <TableCell colSpan={5}>
                <Button
                  variant={"contained"}
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={onAddAnother}
                >
                  Dodaj kolejny
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4}>Łącznie</TableCell>
              <PriceCell><strong>{cart.totalPrice} zł</strong></PriceCell>
              <TableCell/>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Cart;