import React, {useEffect, useState} from "react";
import CartClient, {CartNotFound} from "./cart-client";

const CartStateContext = React.createContext();
const CartActionsContext = React.createContext();

const cartClient = CartClient();

const cartLocalStorageKey = "cartId";

const CartProvider = ({children}) => {
  const [initialLoading, setInitialLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [cart, setCart] = useState(undefined);
  const [errorLoading, setErrorLoading] = useState(false);

  const reset = (onResetDone) => {
    localStorage.removeItem(cartLocalStorageKey);
    setInitialLoading(false);
    setUpdating(false);
    setCart(undefined);
    setErrorLoading(undefined);
    onResetDone && onResetDone();
  }

  const updateQuantity = (itemId, newQuantity) => {
    setUpdating(true);
    return cartClient
      .updateQuantity(cart.id, itemId, newQuantity)
      .then(updatedCart => setCart(updatedCart))
      .catch(() => setUpdating(false))
      .finally(() => setUpdating(false));
  }

  const removeItem = (itemId) => {
    setUpdating(true);
    return cartClient
      .removeItem(cart.id, itemId)
      .then(updatedCart => setCart(updatedCart))
      .catch(() => setUpdating(false))
      .finally(() => setUpdating(false));
  }

  const addItem = (type, params, quantity) => {
    setUpdating(true);
    return cartClient
      .addItem(cart.id, type, quantity, params)
      .then(updatedCart => setCart(updatedCart))
      .catch(() => setUpdating(false))
      .finally(() => setUpdating(false));
  }

  const actions = ({
    updateQuantity, removeItem, addItem, reset
  })

  useEffect(() => {
    const initNewCart = async () => {
      return await cartClient.createNewCart();
    }

    const initCart = async () => {
      const cartId = localStorage.getItem(cartLocalStorageKey);

      if (!cartId) {
        return await initNewCart();
      }

      try {
        return await cartClient.getCart(cartId);
      } catch (error) {
        if (error instanceof CartNotFound) {
          return await initNewCart();
        } else {
          throw error;
        }
      }
    }

    setInitialLoading(true);
    initCart()
      .then(cart => {
        localStorage.setItem(cartLocalStorageKey, cart.id);
        setCart(cart);
      })
      .catch((err) => { setErrorLoading(true); console.log(err); })
      .finally(() => setInitialLoading(false));
  }, []);

  if (!cart) {
    return null;
  }

  if (initialLoading) {
    return <p>Ładowanie...</p>;
  }

  if (errorLoading) {
    return <p>Wystąpił błąd.</p>;
  }

  return (
    <CartActionsContext.Provider value={actions}>
      <CartStateContext.Provider value={{cart, updating}}>
        {children}
      </CartStateContext.Provider>
    </CartActionsContext.Provider>
  )
}

const useCartState = () => {
  const context = React.useContext(CartStateContext);
  if(context === undefined) {
    throw new Error("Must be used within cart context");
  }
  return context;
}

const useCartActions = () => {
  const context = React.useContext(CartActionsContext);
  if(context === undefined) {
    throw new Error("Must be used within cart context");
  }
  return context;
}

export { CartProvider, useCartState, useCartActions }