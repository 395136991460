import React from "react";
import Cart from "../../components/cart/cart";
import { navigate } from "gatsby";
import LoadingOverlay from "../../components/overlay";
import {useCartState} from "../../components/cart-context";
import {BottomNavContainer} from "../../components/general/bottom-nav-container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

const CartPage = (props) => {
  const { updating, cart } = useCartState();

  const goToConfigurator = () => navigate(-1);

  return (
    <>
      <LoadingOverlay open={updating}/>
      <h4>Podgląd zamówienia</h4>
      <Box pb={3}>
        <Cart onAddAnother={goToConfigurator}/>
      </Box>
      <BottomNavContainer>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button variant={"outlined"} onClick={goToConfigurator} fullWidth>Wstecz</Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={"outlined"}
              onClick={() => navigate(`/configure/order`)}
              disabled={cart.items.length === 0}
              fullWidth
            >Dalej</Button>
          </Grid>
        </Grid>
      </BottomNavContainer>
    </>
  )
}

export default CartPage;