import React, {useEffect, useState} from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import styled from "styled-components";

const AddToCartContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.mb}px;
  
  & .MuiTextField-root {
    margin-bottom: ${props => props.mb}px;
  }
`;

const DEFAULT_COLOR = "żółty (RAL 1023)";

const FinishConfig = ({shouldAskForColor, onAddToCart}) => {
  const [quantity, setQuantity] = useState(1);
  const [color, setColor] = useState(DEFAULT_COLOR);
  const theme = useTheme();

  useEffect(() => {
    if (shouldAskForColor === true) {
      setColor(DEFAULT_COLOR);
    } else {
      setColor("");
    }
  }, [shouldAskForColor]);

  useEffect(() => {
    if (shouldAskForColor === true) {
      setColor(DEFAULT_COLOR);
    } else {
      setColor("");
    }
  }, []);

  return (
    <AddToCartContainer mb={theme.spacing(2)}>
      {
        shouldAskForColor === true &&
        <>
          <div>
            <TextField
              label={"Kolor"}
              type={"text"}
              value={color}
              placeholder="Np. RAL 1023"
              onChange={(e) => setColor(e.target.value)}
            />
          </div>
          <Button
            variant={"text"}
            startIcon={<LaunchIcon/>}
            size={"small"}
            style={{
              marginBottom: theme.spacing(2)
            }}
            href="https://www.ralcolor.com/" target="_blank" rel="noreferrer">Zobacz paletę kolorów RAL</Button>
        </>
      }
      <div>
        <TextField
          label="Liczba sztuk"
          type="number"
          inputProps={{
            min: 1
          }}
          value={quantity}
          onChange={(e) => setQuantity(Number.parseInt(e.target.value))}
        />
      </div>
      <div>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => onAddToCart(quantity, color)}
          startIcon={<AddShoppingCartIcon/>}>Dodaj do zamówienia</Button>
      </div>
    </AddToCartContainer>
  );
}

export default FinishConfig;