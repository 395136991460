import {BottomNavContainer} from "../general/bottom-nav-container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";
import {useNavigate} from "@reach/router";
import CheckIcon from '@material-ui/icons/Check';

const BottomNav = ({onSend, canSend}) => {
  const navigate = useNavigate();

  return (
    <BottomNavContainer>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Button variant={"outlined"} onClick={() => navigate(-1)} fullWidth>Wstecz</Button>
        </Grid>
        <Grid item xs={6}>
          <Button variant={"contained"} color={"primary"} onClick={onSend} fullWidth startIcon={<CheckIcon />}>Wyślij</Button>
        </Grid>
      </Grid>
    </BottomNavContainer>
  )
}

export default BottomNav;