import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import useTheme from "@material-ui/core/styles/useTheme";
import Paper from "@material-ui/core/Paper";
import {Button} from "@material-ui/core";
import {useNavigate} from "@reach/router";

const OrderPlacementFailed = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <Box style={{padding: theme.spacing(3), backgroundColor: "#f5f5f5", textAlign: "center"}}>
        <Paper elevation={1} style={{ padding: theme.spacing(3)}}>
          <h1>Wystąpił błąd</h1>
          <p>Przepraszamy, nie udało się złożyć zamówienia. Prosimy spróbować ponownie później lub zadzwonić do nas: <a href={"tel:+48600819114"}>+48 600 819 114</a>.</p>
          <Button onClick={() => navigate("/configure")}>Powrót do sklepu</Button>
        </Paper>
      </Box>
    </Container>
  );
}

export default OrderPlacementFailed;